<div class="modal-substrate" (click)="onModalClose($event)">
  <div class="modal-block" (click)="$event.stopPropagation()" [ngClass]="{primary: color==='primary'}">
    <button *ngIf="type === 'twoAction'" class="close-btn" fill="none" (click)="onModalClose($event)"><mat-icon>close</mat-icon></button>
    <span class="title" [ngClass]="{'small-title-width': smallTitleWidth}">{{titleText}} <mat-icon svgIcon="{{titleIcon}}" *ngIf="titleIcon.length"></mat-icon></span>
    <span class="text" [innerHTML]="text"></span>
    <div class="slots-full-block" *ngIf="slotsFull">
      <mat-icon svgIcon="warning"></mat-icon>
      <span>This shift’s nurse quantity is full, by confirming this nurse you will increase this shift’s quantity.</span>
    </div>
    <div *ngIf="checkbox" class="dontShowAgain" [formGroup]="form">
      <input [ngClass]="{'last-minute-cancel': checkRequiredToSubmit == true}" type="checkbox" [formControlName]="'checkbox'">
      <span>{{checkboxText}}</span>
    </div>
    <div *ngIf="secondCheckbox" class="dontShowAgain second-checkbox" [formGroup]="form">
      <input [ngClass]="{'last-minute-cancel': secondCheckRequiredToSubmit == true}" type="checkbox" [formControlName]="'secondCheckbox'">
      <span>{{secondCheckboxText}}</span>
    </div>
    <div class="buttons-block">
      <button (click)="type === 'twoAction' ? secondModalAction.emit() : onModalClose($event)" fill="outline" color="border-base"  *ngIf="type != 'submit'">{{cancelButtonText}}</button>
      <button [ngClass]="{'disabled-submit':(checkRequiredToSubmit && form.get('checkbox').value != true) || (secondCheckRequiredToSubmit && form.get('secondCheckbox').value != true)}"[disabled]="(checkRequiredToSubmit && form.get('checkbox').value != true) || (secondCheckRequiredToSubmit && form.get('secondCheckbox').value != true)" [color]="color" (click)="onOkClick($event);">{{okButtonText}}</button>
    </div>

  </div>
</div>
