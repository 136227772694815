import { ComponentFactoryResolver, ComponentRef, Injectable, ViewContainerRef } from "@angular/core";
import { Subject } from "rxjs";
import { take } from "rxjs/operators";
import { ModalBoxComponent } from '../../shared/components/modal-box/modal-box.component'

type ModalType = 'modal' | 'submit' | 'twoAction';

export type modalResult = { action: 'closeAction' | 'firstAction' | 'secondAction', checkbox?: boolean };

@Injectable({ providedIn: 'root' })
export class ModalService {
  private componentRef!: ComponentRef<ModalBoxComponent>;
  private componentSubscriber!: Subject<modalResult>;
  entry: ViewContainerRef;
  constructor(private resolver: ComponentFactoryResolver) { }


  openModal(
    modalTitle: string,
    modalBody: string,
    okButtonText: string = 'Ok',
    cancelButtonText: string = 'Cancel',
    color: string = 'rn',
    type: ModalType = 'modal',
    checkbox = false,
    checkboxText = '',
    titleIcon = '',
    checkRequiredToSubmit: boolean = false,
    secondCheckbox = false,
    secondCheckboxText = '',
    secondCheckRequiredToSubmit: boolean = false,
    smallTitleWidth: boolean = false,
    slotsFull: boolean = false
  ) {
    let factory = this.resolver.resolveComponentFactory(ModalBoxComponent);
    this.componentRef = this.entry.createComponent(factory);
    this.componentRef.instance.titleText = modalTitle;
    this.componentRef.instance.text = modalBody;
    this.componentRef.instance.okButtonText = okButtonText;
    this.componentRef.instance.cancelButtonText = cancelButtonText;
    this.componentRef.instance.color = color;
    this.componentRef.instance.type = type;
    this.componentRef.instance.checkbox = checkbox;
    this.componentRef.instance.checkboxText = checkboxText;
    this.componentRef.instance.secondCheckbox = secondCheckbox;
    this.componentRef.instance.secondCheckboxText = secondCheckboxText;
    this.componentRef.instance.smallTitleWidth = smallTitleWidth;
    this.componentRef.instance.secondCheckRequiredToSubmit = secondCheckRequiredToSubmit,
    this.componentRef.instance.titleIcon = titleIcon;
    this.componentRef.instance.checkRequiredToSubmit = checkRequiredToSubmit,
    this.componentRef.instance.slotsFull = slotsFull,
    this.componentRef.instance.closeAction.subscribe(() => this.closeModal());
    this.componentRef.instance.modalAction.subscribe(() => this.confirm());
    this.componentRef.instance.secondModalAction.subscribe(() => this.secondModalAction());
    this.componentRef.instance.modalActionWithCheckbox.subscribe((e) => this.modalActionWithCheckbox(e));
    this.componentRef.instance.modalCloseActionWithCheckbox.subscribe((e) => this.modalCloseActionWithCheckbox(e));
    this.componentSubscriber = new Subject<modalResult>();
    return this.componentSubscriber.asObservable();
  }

  closeModal() {
    this.componentSubscriber.next({action: 'closeAction'});
    this.componentSubscriber.complete();
    this.componentRef.destroy();
  }

  confirm() {
    this.componentSubscriber.next({action: 'firstAction'});
    this.closeModal();
  }
  secondModalAction() {
    this.componentSubscriber.next({action: 'secondAction'});
    this.closeModal();
  }
  modalActionWithCheckbox(e) {
    this.componentSubscriber.next({action: 'firstAction', checkbox: e});
    this.closeModal();
  }
  modalCloseActionWithCheckbox(e) {
    this.componentSubscriber.next({action: 'closeAction', checkbox: e});
    this.closeModal();
  }
  
  showModal(
    title: string,
    text: string,
    okButtonText: string,
    cancelButtonText: string = 'Cancel',
    type: string = 'rn',
    modalType: ModalType = 'modal',
    checkbox = false,
    checkboxText = '',
    titleIcon = '',
    checkRequiredToSubmit: boolean = false,
    secondCheckbox = false,
    secondCheckboxText = '',
    secondCheckRequiredToSubmit: boolean = false,
    smallTitleWidth: boolean = false,
    slotsFull: boolean = false
  ) {
    return this.openModal(title, text, okButtonText, cancelButtonText, type, modalType, checkbox, checkboxText, titleIcon, checkRequiredToSubmit, secondCheckbox, secondCheckboxText, secondCheckRequiredToSubmit, smallTitleWidth, slotsFull)
      .pipe(take(1));
  }
}
