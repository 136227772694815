<div class="input-container">
  <span *ngIf="label" class="label {{ labelColor }}" [ngClass]="{ disabled: disabled }" [style]="{'font-size': labelSize + 'rem'}">{{ label }}</span>
  <mat-icon *ngIf="labelTooltipText" class="info-icon" [svgIcon]="'info-2'" customToolTip [contentTemplate]="template" [offsetY]="-8"></mat-icon>
  <span *ngIf="!label" class="empty-label"></span>
  <ng-template #template>
    <div class="label-tooltip">
     <span>{{labelTooltipText}}</span> 
    </div>
  </ng-template>
  <div class="input-block" [ngClass]="{ textAreaFlex: textAreaAutosize }">
    <ng-container [ngSwitch]="type">
      <ng-container
        *ngSwitchCase="'spin'"
        [ngTemplateOutlet]="spin"
      ></ng-container>
      <ng-container
        *ngSwitchCase="'money'"
        [ngTemplateOutlet]="spin"
      ></ng-container>
      <ng-container
        *ngSwitchCase="'date'"
        [ngTemplateOutlet]="date"
      ></ng-container>
      <ng-container
        *ngSwitchCase="'select'"
        [ngTemplateOutlet]="select"
      ></ng-container>
      <ng-container
        *ngSwitchCase="'text'"
        [ngTemplateOutlet]="text"
      ></ng-container>
      <ng-container
        *ngSwitchCase="'password'"
        [ngTemplateOutlet]="text"
      ></ng-container>
      <ng-container
        *ngSwitchCase="'textArea'"
        [ngTemplateOutlet]="textArea"
      ></ng-container>
      <ng-container
        *ngSwitchCase="'customTime'"
        [ngTemplateOutlet]="text"
      ></ng-container>
      <ng-container
        *ngSwitchCase="'iconSelect'"
        [ngTemplateOutlet]="iconSelect"
      ></ng-container>
      <ng-container
        *ngSwitchCase="'phone'"
        [ngTemplateOutlet]="text"
      ></ng-container>
    </ng-container>
  </div>
  <div
    *ngIf="
      !isValid &&
      type !== 'text' &&
      type !== 'password' &&
      type !== 'textArea' &&
      type !== 'phone'
    "
    class="error-block"
  >
    <span class="error-string">{{ errorString }}</span>
  </div>
</div>

<ng-template #spin>
  <span
    [ngClass]="{ disabled: disabled }"
    class="dollar-sign"
    *ngIf="type === 'money'"
    >$</span
  ><input
    type="{{this.type === 'money' ? 'number' : 'phone'}}"
    [maxlength]="maxlength"
    [ngModel]="value[this.type]"
    [disabled]="disabled"
    class="input"
    (change)="onTextChange($event)"
    [ngClass]="{ error: !isValid, disabled: disabled, money: type === 'money'}"
    (blur)="onBlur($event)"
  />
  <div class="buttons-container" [ngClass]="{ disabled: disabled }">
    <button (click)="onSpinAdd()" fill="none">
      <mat-icon class="chevron-up" svgIcon="mdCheveronUp-01"></mat-icon>
    </button>
    <button (click)="onSpinRemove()" fill="none">
      <mat-icon class="chevron-down" svgIcon="mdCheveronUp-01"></mat-icon>
    </button>
  </div>
</ng-template>

<ng-template #date>
  <input
    class="input"
    matInput
    [ngModel]="value[this.type]"
    (click)="picker.open()"
    [matDatepicker]="picker"
    (dateInput)="onDateChange($event.value)"
    [ngClass]="{ error: !isValid, disabled: disabled }"
    [disabled]="disabled"
  />
  <mat-icon
    class="date-icon"
    (click)="picker.open()"
    svgIcon="schedule-01"
    [ngClass]="{ disabled: disabled }"
  ></mat-icon>
  <mat-datepicker #picker></mat-datepicker>
</ng-template>

<ng-template #select>
  <mat-select
    class="input input-select"
    (selectionChange)="onSelectChange(Select.value.id)"
    [(value)]="defaultSelected"
    #Select
    [disabled]="disabled"
    [ngClass]="{ disabled: disabled, 'wide-padding': widePadding }"
    disableOptionCentering
    [panelClass]="widePadding ? 'input-select-panel-wide' : 'input-select-panel'"
    [placeholder]="placeholder"
  >
    <mat-select-trigger class="{{selectLicenses ? defaultSelected?.text : null}}">{{defaultSelected?.text}}</mat-select-trigger>
    <mat-option *ngFor="let item of selectData" [value]="item" class="{{selectLicenses ? item.text: null}}">
      <ng-container *ngIf="!item.addNew; else newShift">
      {{ item.text }}
      </ng-container>
      <ng-template #newShift>
        <div style="display: flex; align-items: center" (click)="showAddShiftTimeModal()">
          <div style="flex: 1">
            <mat-icon [svgIcon]="'editAdd'"></mat-icon>
            <button fill="none" class="add-shift-button"><span>{{ item.text }}</span></button>
          </div>
        </div>
      </ng-template>
    </mat-option>
  </mat-select>

  <mat-icon
    class="select-chevron"
    svgIcon="mdCheveronUp-01"
    [ngClass]="{ disabled: disabled, 'select-chevron-down': Select.panelOpen, 'wide-padding-chevron': widePadding }"
    style="cursor: pointer"
    (click)="Select.open()"
  ></mat-icon>
</ng-template>
<ng-template #text>
  <input
    class="input"
    [ngModel]="value[this.type]"
    [ngClass]="{
      error: (!isValid && (inputNgModel.touched || inputNgModel.dirty)) || error
    }"
    [placeholder]="placeholder"
    [readonly]="readonly"
    (input)="onTextChange($event)"
    #inputNgModel="ngModel"
    [type]="type"
    (blur)="onBlur($event)"
    [disabled]="disabled"
  />
  <div
    *ngIf="!isValid && (inputNgModel.touched || inputNgModel.dirty)"
    class="error-block text"
  >
    <span>{{ errorString }}</span>
  </div>
</ng-template>
<ng-template #textArea>
  <textarea
    cdkTextareaAutosize
    #autosize="cdkTextareaAutosize"
    cdkAutosizeMinRows="1"
    [disabled]="disabled"
    class="input"
    [ngModel]="value[this.type]"
    [ngClass]="{
      error: !isValid && (inputNgModel.touched || inputNgModel.dirty), textArea: !textAreaAutosize, textAreaResize: textAreaAutosize, disabled: disabled
    }"
    [placeholder]="placeholder"
    [readonly]="readonly"
    (input)="onTextChange($event)"
    #inputNgModel="ngModel"
  ></textarea>
  <div
    *ngIf="!isValid && (inputNgModel.touched || inputNgModel.dirty)" class="error-block" [ngClass]="{ textArea: !textAreaAutosize, textAreaAutosize: textAreaAutosize }">
    <span>{{ errorString }}</span>
  </div>
</ng-template>
<ng-template #iconSelect>
  <div class="readonly-wrapper" *ngIf="readonly"></div>
  <mat-select
    placeholder="Select shift time"
    [(value)]="value[this.type]"
    [compareWith]="compareFn"
    class="input iconSelect"
    (selectionChange)="onSelectChange(value[this.type])"
    #IconSelect
    [panelClass]="['input-select-panel', 'extra-height-panel']"
    [disableOptionCentering]="true"
  >
    <mat-select-trigger>
      <div class="triger-block">
        <div style="align-items: center; display: flex">
          <mat-icon
            [svgIcon]="
              iconsFormattedObj[value[this.type]]
                ? iconsFormattedObj[value[this.type]].icon
                : ''
            "
            class="icon"
          ></mat-icon>

          <span>{{
            iconsFormattedObj[value[this.type]]
              ? iconsFormattedObj[value[this.type]].shiftName
              : ''
          }}</span>
        </div>
        <div style="flex: 1; margin-left: 8px">
          <span style="font-weight: bold; margin-right: 4px">· </span>
          <span style="text-transform: lowercase">{{
            iconsFormattedObj[value[this.type]]
              ? iconsFormattedObj[value[this.type]].shiftTime
              : ''
          }}</span>
        </div>
      </div>
    </mat-select-trigger>
    <mat-option *ngFor="let icon of selectIconsObj" [value]="icon.id" [disabled]="icon.disabled" style="padding-inline-end: 8px;">
      <ng-container *ngIf="iconsFormattedObj[icon.id]">
        <ng-container *ngIf="!icon.addNew; else newShift">
          <div style="display: flex; align-items: center">
            <div style="flex: 1">
              <mat-icon [svgIcon]="icon.icon"></mat-icon>
              <span>{{ iconsFormattedObj[icon.id].shiftName }}</span>
            </div>
            <div style="flex: 1">
              <span style="font-weight: bold; margin-right: 4px">· </span>
              <span style="text-transform: lowercase">{{
                iconsFormattedObj[icon.id].shiftTime
              }}</span>
            </div>
          </div>
        </ng-container>
        <ng-template #newShift>
          <div style="display: flex; align-items: center" (click)="showAddShiftTimeModal()">
            <div style="flex: 1">
              <mat-icon [svgIcon]="icon.icon"></mat-icon>
              <button fill="none" class="add-shift-button"><span>{{ iconsFormattedObj[icon.id].shiftName }}</span></button>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </mat-option>
  </mat-select>

  <mat-icon
    [ngClass]="{
      disabled: disabled,
      'select-chevron-down': IconSelect.panelOpen
    }"
    class="select-chevron icon"
    svgIcon="mdCheveronUp-01"
    (click)="IconSelect.open()"
  ></mat-icon>
</ng-template>
