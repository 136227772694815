import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { UserAccessor } from 'src/app/services/accessors/user.accessor';
import { UserFacade } from 'src/app/services/facades/user/user.facade';
import { MixpanelService } from 'src/app/services/mixpanel.service';
import { ModalService } from './ui/modal.service';

@Injectable({
  providedIn: 'root',
})
export class NurseConfirmationService {
  constructor(
    private modalService: ModalService,
    private router: Router,
    private userFacade: UserFacade,
    private userAccessor: UserAccessor,
    private mixpanel: MixpanelService
  ) {}
  private applicant;
  private facility;
  private shift;
  private userId = '';
  onNurseConfirm(applicant, facility, shift) {
    this.applicant = applicant;
    this.facility = facility;
    this.shift = shift;
    let modalsData = [];
    type modalType =
      | 'denyOvertimeApplicants'
      | 'holidayOvertime'
      | 'holiday'
      | 'overtime'
      | 'lastMinuteConfirmation'
      | 'appliedOver3Days'
      | 'slotsFull'
      | '';
    let modalsType: modalType[] = [];
    const numberOfConfirmed = this.shift.applicants.filter(a => a.status === 'confirmed').length;
    const slotsFull =  numberOfConfirmed === this.shift.slots;

    if (applicant.isInOvertime && facility.denyOvertimeApplicants) {
      this.userAccessor.user$.pipe(take(1)).subscribe(u=>{

        let modalData = {};


        if (u.position != 'supervisor') {
          modalData = {
            title: 'Overtime pay is disabled',
            text: 'Confirming a nurse with overtime has been disabled. Please review your overtime settings if you would like to make a change.',
            okButtonText: 'Go to overtime settings',
            cancelButtonText: 'Cancel',
            type: 'primary',
            isSupervisor: false
          };
        }else{
          modalData = {
            title: 'Overtime pay is disabled',
            text: 'Confirming a nurse with overtime has been disabled. Please contact a facility manager if you’d like to enable overtime pay.',
            okButtonText: 'Contact a facility manager',
            cancelButtonText: 'Cancel',
            type: 'primary',
            isSupervisor: true
          };
        }
        modalsData.push(modalData);
        modalsType.push('denyOvertimeApplicants');
      })



    } else {
      if (shift.isHoliday && applicant.isInOvertime) {
        let modalData = {
          title: 'Holiday & Overtime',
          text: 'This nurse is in overtime for this holiday shift. The nurse will be paid double the nurse hourly pay rate for this shift and you will be billed at twice the facility hourly bill rate to account for the holiday and the overtime.',
          okButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          type: 'rn',
          slotsFull: slotsFull
        };
        modalsData.push(modalData);
        modalsType.push('holidayOvertime');
      } else if (shift.isHoliday) {
        let modalData = {
          title: 'Holiday rate',
          text: 'This shift is on a holiday, which means you’ll pay a time-and-a-half holiday rate.',
          okButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          type: 'rn',
          slotsFull: slotsFull
        };
        modalsData.push(modalData);
        modalsType.push('holiday');
      } else if (applicant.isInOvertime) {
        let modalData = {
          title: 'Overtime rate',
          text: 'This nurse will be in overtime for this shift, which means you’ll pay a time-and-a-half overtime rate.',
          okButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          type: 'rn',
          slotsFull: slotsFull
        };
        modalsData.push(modalData);
        modalsType.push('overtime');
      }
    }
    const nowTimestamp = DateTime.fromObject({
      zone: this.shift.facility.timezone,
    }).valueOf();

    if (this.shift.shiftFrom.valueOf() - nowTimestamp < 18000000) {
      // 18000000 = 5h
      let modalData = {
        title: 'Last-minute confirmation!',
        text:
          'You’re confirming ' +
          this.applicant.user.name.first +
          ' for this shift close to its start time. Please contact ' +
          this.applicant.user.name.first +
          " to check they're still available to work this shift. </br></br> It's best to confirm nurses as soon as they apply to your shift.",
        okButtonText: 'Confirm nurse',
        cancelButtonText: 'Contact nurse',
        type: 'primary',
        modalType: 'twoAction',
        slotsFull: slotsFull
      };
      modalsData.push(modalData);
      modalsType.push('lastMinuteConfirmation');
    } else {
      this.userFacade.state.user$.pipe(take(1)).subscribe((u) => {
        this.userId = u._id;
        let flag = false;
        let dontShowAgain = JSON.parse(localStorage.getItem('dontShowAgain'));
        let userDontShowAgain;
        if (dontShowAgain) {
          userDontShowAgain = dontShowAgain.find((e) => e.userId == u._id);
          if (userDontShowAgain.modalType === 'appliedOver3Days') {
            flag = true;
          }
        }
        if(!flag){
          const nowTimestamp = DateTime.fromObject({
            zone: this.shift.facility.timezone,
          }).valueOf();
          const appliedTimestamp = DateTime.fromISO(
            applicant.updatedAt,
            {
            zone: this.shift.facility.timezone,
          }).valueOf();
          if (nowTimestamp - appliedTimestamp > 259200000) { // 259200000 = 3d
            let modalData = {
              title: 'Confirm ' + this.applicant.user.fullName + ' to the shift',
              text: "This nurse applied to your shift over <b>3 days ago</b>. Please contact the nurse to check that they're still available to work this shift.  It's best to confirm nurses as soon as they apply to your shift.",
              okButtonText: 'Confirm nurse',
              cancelButtonText: 'Contact nurse',
              type: 'primary',
              modalType: 'twoAction',
              checkbox: true,
              checkboxText: "Don't show this message again",
              slotsFull: slotsFull

            };
            modalsData.push(modalData);
            modalsType.push('appliedOver3Days');
          }
        }
      });
      if(modalsData.length === 0) {
        if(slotsFull) {
          let modalData = {
            title: 'Confirm nurse and increase nurse quantity',
            text: "This shift’s nurse quantity is full, by confirming this nurse you will increase this shift’s quantity.",
            okButtonText: 'Confirm nurse',
            cancelButtonText: 'Cancel',
            type: 'primary'
          };
          modalsData.push(modalData);
          modalsType.push('slotsFull');
        }
      }

    }

    return this.showModals(modalsType, modalsData, slotsFull);
  }
  showModals(modalsType, modalsData, slotsFull: boolean) {
    return new Observable((subscriber) => {
      if (!modalsType.length) {
        this.mixpanel.for('fm').track('SHIFTS_PAGE_CONFIRM_NURSE', {shift: this.shift, applicant: this.applicant, overtime: this.applicant.isInOvertime});
        this.applicant.confirm(slotsFull);
        subscriber.next(true);
      }else if (modalsType[0] === 'denyOvertimeApplicants') {
        this.modalService
          .showModal(
            modalsData[0].title,
            modalsData[0].text,
            modalsData[0].okButtonText,
            modalsData[0].cancelButtonText,
            modalsData[0].type,
          )
          .subscribe((r) => {
            if (r.action === 'firstAction') {
              // let mail = document.createElement('a');
              // mail.href =
              //   'mailto:info@eshyft.com?subject=[FM%20request]%20Overtime%20pay%20for%20' +
              //   this.facility.name +
              //   '&body=Hi+there%2C%0D%0A%0D%0AOvertime+pay+is+disabled+at+my+facility.+Can+I+get+it+enabled%3F%0D%0A%0D%0AThanks.';
              // mail.target = '_blank';

              // mail.click();
              // mail.remove();


              if(modalsData[0].isSupervisor){
                this.router.navigate(['manager/settings/facility']);
              }else{
                this.router.navigate(['manager/settings/overtime']);
              }

            }
            subscriber.next(false);
          });
      } else {
        this.showModalsOneByOne(modalsData, 0, modalsType, subscriber, slotsFull);
      }
    });
  }
  showModalsOneByOne(modalsData, i, modalsType, subscriber, slotsFull) {
    this.modalService
      .showModal(
        modalsData[i].title,
        modalsData[i].text,
        modalsType.length - 1 != i ? 'Next' : modalsData[i].okButtonText,
        modalsData[i].cancelButtonText,
        modalsData[i].type,
        modalsData[i].modalType ? modalsData[i].modalType : 'modal',
        modalsData[i].checkbox ? modalsData[i].checkbox : false,
        modalsData[i].checkboxText ? modalsData[i].checkboxText : '',
        '',
        false,
        false,
        '',
        false,
        false,
        modalsData[i].slotsFull
      )
      .subscribe((r) => {
        console.log('r',r)
        if(r.checkbox){
          localStorage.setItem(
            'dontShowAgain',
            JSON.stringify([
              {
                userId: this.userId,
                modalType: 'appliedOver3Days',
              },
            ]),
          );
        }
        if (r.action === 'firstAction') {
          this.trackModalAction(true, modalsType, i);
          if (modalsType.length - 1 != i) {
            setTimeout(() => {
              this.showModalsOneByOne(
                modalsData,
                i + 1,
                modalsType,
                subscriber,
                slotsFull
              );
            }, 1);
          } else {
            this.mixpanel.for('fm').track('SHIFTS_PAGE_CONFIRM_NURSE', {shift: this.shift, applicant: this.applicant, overtime: this.applicant.isInOvertime});
           this.applicant.confirm(slotsFull);
            subscriber.next(true);
          }
        } else if (r.action === 'secondAction') {
          this.router.navigate(['manager/chat'], {
            state: { shift: this.shift.model, nurse: this.applicant.user },
          });
        } else {
          this.trackModalAction(false, modalsType, i)
          subscriber.next(false);
        }
      });
  }
  trackModalAction(confirm: boolean, modalsType, i){
    if(confirm){
      switch (modalsType[i]) {
        case 'overtime':
          this.mixpanel.for('fm').track('OVERTIME_MODAL_CONFIRM', {shift: this.shift, applicant: this.applicant, overtime: this.applicant.isInOvertime});
          break;
        case 'holiday':
          this.mixpanel.for('fm').track('HOLIDAY_MODAL_CONFIRM', {shift: this.shift, applicant: this.applicant, overtime: this.applicant.isInOvertime});
          break;
        case 'holidayOvertime':
          this.mixpanel.for('fm').track('HOLIDAY_AND_OVERTIME_MODAL_CONFIRM', {shift: this.shift, applicant: this.applicant, overtime: this.applicant.isInOvertime});
          break;
        default:
          break;
      }
    }else{
      switch (modalsType[i]) {
        case 'overtime':
          this.mixpanel.for('fm').track('OVERTIME_MODAL_CANCEL', {shift: this.shift, applicant: this.applicant, overtime: this.applicant.isInOvertime});
          break;
        case 'holiday':
          this.mixpanel.for('fm').track('HOLIDAY_MODAL_CANCEL', {shift: this.shift, applicant: this.applicant, overtime: this.applicant.isInOvertime});
          break;
        case 'holidayOvertime':
          this.mixpanel.for('fm').track('HOLIDAY_AND_OVERTIME_MODAL_CANCEL', {shift: this.shift, applicant: this.applicant, overtime: this.applicant.isInOvertime});
          break;
        default:
          break;
      }
    }
  }

}
